<template>
  <section class="searchpage">
    <div
      class="headbanner"
      :style="{ backgroundImage: `url(${bannerImage})` }"
    ></div>

    <v-container class="container-custom">
      <v-row>
        <v-col md="3">
          <div class="boxinside">
            <h3>Filter</h3>
            <div v-if="loading">
              <v-skeleton-loader
                max-width="420"
                type="image"
                height="200"
              ></v-skeleton-loader>
              <v-skeleton-loader
                max-width="420"
                type="image"
                height="100"
              ></v-skeleton-loader>
            </div>
            <v-form
              ref="filtersearch"
              v-model="valid"
              lazy-validation
              class="form-section"
              v-if="!loading"
            >
              <div class="mr-btm">
                <h4>Category</h4>

                <v-radio-group v-model="searchKey" class="filter-list">
                  <v-radio-group>
                    <v-radio
                      v-for="(item, index) in allVideoCategory.results.list"
                      :label="item.title"
                      :value="item.title"
                      :key="index"
                      @change="searchKey = item.title"
                    ></v-radio>
                  </v-radio-group>
                </v-radio-group>
              </div>

              <div class="mr-btm">
                <v-btn
                  class="btn-filter"
                  :loading="loadingFilter"
                  @click="filterVideos"
                  >Filter</v-btn
                >
              </div>
            </v-form>
          </div>
        </v-col>
        <v-col md="9">
          <div class="content-box">
            <div class="video-section">
              <v-row v-if="loading">
                <v-col v-for="n in 3" :key="n">
                  <v-skeleton-loader
                    max-width="420"
                    type="image"
                    height="200"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    max-width="420"
                    type="image"
                    height="100"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row v-if="!loading">
                <v-col
                  md="4"
                  v-for="(item, index) in allVideoSubCategory.results.list"
                  :key="index"
                >
                  <div class="video-list">
                    <router-link
                      :to="{ name: 'VideoPage', params: { id: item._id } }"
                    >
                      <img
                        :src="'https://app.necojobs.com.np' + item.imageUrl"
                        alt=""
                        class="videoImage"
                      />
                    </router-link>

                    <h4>{{ item.title }}</h4>
                    <p>{{ item.author }}</p>
                    <small>{{ item.videos }} Videos</small>
                  </div>
                </v-col>
                <v-col md="12" v-if="allVideoSubCategory.results.list.length == 0">
                  <h3>No Course Found</h3>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="!loading">
            <v-pagination
              v-if="allVideoSubCategory.results.pagination.next"
              v-model="page"
              :length="allVideoSubCategory.results.pagination.total"
              @input="fetchVideoSubCategory"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SearchVideo",
  data: () => ({
    bannerImage: require("@/assets/searchBanner.jpg"),
    checkbox: false,
    valid: false,
    title: "",
    status: true,
    limit: 10,
    page: 1,
    loading: true,
    loadingFilter: false,
    jobData: {
      title: "Sample Job Title",
      company: "Sample Company",
      location: "Sample Location",
      description: "Sample job description...",
    },
    category: [
      {
        title: "Accounts",
      },
      {
        title: "Website Development",
      },
      {
        title: "App Development",
      },
      {
        title: "Software Development",
      },
      {
        title: "DevOps",
      },
    ],
    searchKey: "",
  }),

  methods: {
    async filterVideos() {
      this.loadingFilter = true;
      await this.fetchVideoSubCategory({
        limit: this.limit,
        skip: this.skip,
        categoryType: "video",
        search: this.searchKey,
      });
      this.loadingFilter = false;
    },
    ...mapActions([
      "fetchVideo",
      "fetchCategoryVideo",
      "fetchVideoSubCategory",
    ]),
  },
  computed: {
    ...mapGetters([
      "allVideo",
      "allLoadingCurd",
      "allVideoCategory",
      "allVideoSubCategory",
    ]),
  },
  async created() {
    await this.fetchVideo({
      status: true,
      category: this.selectedCategory,
      subCategory: this.selectedSubCategory,
      limit: this.limit,
      page: this.page,
    });
    await this.fetchCategoryVideo({
      limit: this.limit,
      skip: this.skip,
      categoryType: "video",
    });
    await this.fetchVideoSubCategory({
      limit: this.limit,
      skip: this.skip,
      categoryType: "video",
      search: this.$route.query.search,
    });
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.searchpage {
  background: #f8f8f8;
  overflow: hidden;

  .boxinside {
    background: $white;
    padding: 13px 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    // border-radius: 6px;
  }
}

h3 {
  font-size: $iconfont;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #bbbbbb;
}

.filter-list {
  flex-flow: column;
  display: flex;

  li {
    margin-left: 0;
    padding: 0;
  }
}

h4 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.mr-btm {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.content-box {
  background: white;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.container-custom {
  padding: 50px 0;
}

.btn-filter {
  background: $tagcolor !important;
  width: 100%;
  color: $white;
}
.btn-dark {
  background: $tagcolor !important;
  border-radius: 50px;
  color: white;
}

ul {
  list-style-type: none;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }
}

.headbanner {
  background-size: cover;
  height: 450px;
  width: 100%;
  background-position: center center;
}

@media screen and (max-width: 992px) {
  .headbanner {
    background-size: cover;
    height: 120px;
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    .container-custom {
      padding: 40px 10px;
    }
  }
}
.video-list {
  min-height: 270px;
  background-size: cover;
  background-position: center;

  overflow: hidden;
  .videoImage {
    height: 280px;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
}
</style>
